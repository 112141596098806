@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('/assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('/assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
  url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
  url('/assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
  url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/Roboto/RobotoMono-Medium.woff2') format('woff2'),
  url('/assets/fonts/Roboto/RobotoMono-Medium.woff') format('woff'),
  url('/assets/fonts/Roboto/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/Roboto/RobotoMono-Bold.woff2') format('woff2'),
  url('/assets/fonts/Roboto/RobotoMono-Bold.woff') format('woff'),
  url('/assets/fonts/Roboto/RobotoMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/Roboto/RobotoMono-Regular.woff2') format('woff2'),
  url('/assets/fonts/Roboto/RobotoMono-Regular.woff') format('woff'),
  url('/assets/fonts/Roboto/RobotoMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

