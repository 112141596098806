@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("/assets/fonts/Roboto/Roboto-Regular.woff") format("woff"), url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Bold.woff2") format("woff2"), url("/assets/fonts/Roboto/Roboto-Bold.woff") format("woff"), url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"), url("/assets/fonts/Roboto/Roboto-Medium.woff") format("woff"), url("/assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/Roboto/RobotoMono-Medium.woff2") format("woff2"), url("/assets/fonts/Roboto/RobotoMono-Medium.woff") format("woff"), url("/assets/fonts/Roboto/RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/Roboto/RobotoMono-Bold.woff2") format("woff2"), url("/assets/fonts/Roboto/RobotoMono-Bold.woff") format("woff"), url("/assets/fonts/Roboto/RobotoMono-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("/assets/fonts/Roboto/RobotoMono-Regular.woff2") format("woff2"), url("/assets/fonts/Roboto/RobotoMono-Regular.woff") format("woff"), url("/assets/fonts/Roboto/RobotoMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

html.theme-dark {
  filter: invert(1) hue-rotate(180deg);
}

body {
  min-height: 100%;
  background-color: #f1f1f1;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr;
  font-family: "Roboto Mono", sans-serif;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.link {
  color: inherit;
}

.link--color-blue {
  color: #0153ae;
}

.btn {
  display: inline-block;
  border: 2px solid #000000;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-family: "Roboto Mono", sans-serif;
  font-weight: 500;
  text-align: center;
  max-width: 200px;
  width: 100%;
  padding: 12px 16px;
  outline: none;
  cursor: pointer;
  margin: 0;
}

.btn:not(.btn--hover-disabled):active {
  background-color: #000000;
  color: #ffffff;
}

.btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.btn-link {
  text-decoration: none;
}

.btn--round {
  border-radius: 16px;
  padding: 4px 16px;
}

.btn--wide {
  max-width: 100%;
}

.btn--weight-normal {
  font-weight: normal;
}

.btn--inverse {
  background-color: #000000;
  color: #ffffff;
}

.btn--shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.btn--shadow:active {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.24);
}

.title {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
}

.title--size-l {
  font-size: 18px;
}

.title--weight-normal {
  font-weight: normal;
}

.text {
  font-size: 14px;
}

.text--size-xs {
  font-size: 10px;
}

.text--size-s {
  font-size: 12px;
}

.text--size-l {
  font-size: 16px;
}

.text--color-primary {
  color: #000000;
}

.text--color-secondary {
  color: #808080;
}

.text--color-red {
  color: #d72200;
}

.text--color-green {
  color: #03a500;
}

.text--center {
  text-align: center;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--bold {
  font-weight: 500;
}

.text--extra-bold {
  font-weight: bold;
}

.text--no-wrap {
  white-space: nowrap;
}

.text--padding-bottom {
  padding-bottom: 16px;
}

.text--shadow-right {
  position: relative;
}
.text--shadow-right::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 20%;
  min-width: 96px;
  background: linear-gradient(275deg, #ffffff 10%, transparent);
  pointer-events: none;
}

.input-row {
  position: relative;
}

.input-actions {
  top: 0;
  right: 0;
  width: auto;
  background: #ffffff;
  display: grid;
  grid-auto-flow: column;
  column-gap: 12px;
}
.input-actions > * {
  cursor: pointer;
}

.input {
  border: none;
  border-bottom: 2px solid #d0d0d0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto Mono", sans-serif;
  color: #000000;
  padding-bottom: 6px;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
  width: 100%;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.input::placeholder {
  color: #808080;
}

.input:read-only {
  cursor: default;
}

.input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  color: #808080;
}

.input-error {
  color: #d72200;
  font-size: 12px;
  padding-top: 4px;
  box-sizing: border-box;
  display: block;
}

.page-row {
  padding: 0 32px;
  box-sizing: border-box;
}

.page-head {
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 0 32px;
  background-color: #ffffff;
  width: 100%;
  max-width: 520px;
  min-width: 320px;
  height: 66px;
  z-index: 50;
}

.page-content {
  padding-top: 66px;
  padding-bottom: 16px;
  max-width: 100vw;
}

.page-content--padding-top {
  padding-top: 66px;
  padding-bottom: 16px;
  max-width: 100vw;
}

.wrapper--center {
  display: flex;
  justify-content: center;
}

.currency__icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.currency__icon {
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
}

img.currency__icon--platform {
  position: absolute;
  z-index: 1;
  bottom: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  background-color: #ffffff;
  border-radius: 50%;
}

.theme-dark .currency__icon {
  filter: invert(1) hue-rotate(180deg);
}

.currency__no-icon {
  text-align: center;
  line-height: 1;
}

.address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.row-content {
  padding: 16px 0;
}

.row-content--underline {
  border-bottom: 1px solid #e5e5e5;
}

.menu-row--wrapper {
  cursor: pointer;
}

.menu-row--disabled {
  cursor: default;
  opacity: 0.6;
}

.menu-row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-items: end;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}

.arrow {
  transform-origin: center;
  transform: rotate(-45deg);
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  width: 10px;
  height: 10px;
}

.arrow--right {
  transform: rotate(45deg);
}

.view {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 520px;
  min-width: 320px;
  background-color: #ffffff;
  z-index: 3;
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.view--with-menu {
  z-index: 1;
  padding-bottom: 58px;
}

.view--hidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  z-index: -2;
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);
  z-index: 1;
}

.theme-dark .modal-wrapper {
  filter: invert(1) hue-rotate(180deg);
}

.modal {
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
}

html.theme-dark .prevent-theme-dark {
  filter: invert(1) hue-rotate(180deg);
}

@media (hover: hover) {
  .btn:not(.btn--hover-disabled):not(:disabled):hover {
    background-color: #000000;
    color: #ffffff;
  }

  .menu-row--wrapper:not(.menu-row--disabled):hover {
    background-color: #e5e5e5;
  }
}
@media (max-width: 425px) {
  .page-row {
    padding-left: 16px;
    padding-right: 16px;
  }

  .page-head {
    padding: 16px;
  }
}
.action-image {
  height: 16px;
  width: 16px;
  cursor: pointer;
  display: block;
}